<style lang="less" scoped>
  .org-block {
    display: flex;justify-content: space-between;padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #EEE;
  }
  .org-item {
    flex: 1;
    border: 1px solid #EEE;
    padding: 10px;
    border-radius: 5px;
    & + .org-item {
      margin-left: 30px;
    }
  }
  .org-title {
    margin-bottom: 10px;
  }
</style>

<template>
  <div class="budget-form">
    <fm-form>
      <fm-form-item label="预算年份">
        <fm-date-picker v-verifier :readonly="value.id && true" required v-model="value.year" type="year" placeholder="请选择年份"></fm-date-picker>
      </fm-form-item>
      <fm-form-item label="预算金额">
        <fm-input-new v-verifier required :rules="[{type: 'number'}]" v-model="value.total" placeholder="请输入预算金额"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="名称">
        <fm-input-new v-verifier v-model="value.name" placeholder="请输入名称"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="是否追加预算">
        <fm-select placeholder="是否追加预算" absolute filterable :clearable="true" v-model="value.type">
          <fm-option v-for="item in typeList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
    </fm-form>
    <div class="org-block">
      <div class="org-item">
        <div class="org-title">申报机构:</div>
        <org-choose style="max-height: 350px;" v-model="value.applyOrgIds" />
      </div>
      <div class="org-item">
        <div class="org-title">初审机构:</div>
        <org-choose style="max-height: 350px;" v-model="value.preOrgIds" />
      </div>
      <div class="org-item">
        <div class="org-title">分管领导:</div>
        <user-choose v-model="value.fgldUserIds" />
      </div>
      <div class="org-item">
        <div class="org-title">终审机构:</div>
        <org-choose style="max-height: 350px;" :multiple="false" v-model="value.finalOrgId" />
      </div>
    </div>
  </div>
</template>

<script>
import OrgChoose from '../../../../components/org.vue'
import UserChoose from '../../../../components/user.vue'

function getDefault () {
  return {
    year: new Date(new Date().getFullYear() + 1),
    total: null,
    name: '',
    type: 'normal',
    applyOrgIds: [],
    preOrgIds: [],
    fgldUserIds: [],
    finalOrgId: null
  }
}

function transData (data) {
  return JSON.parse(JSON.stringify(data, function (key, value) {
    if (['applyOrgIds', 'preOrgIds', 'fgldUserIds'].includes(key)) {
      return value ? value.split(',').map(v => Number(v)) : []
    } else {
      return value
    }
  }))
}

export default {
  components: { OrgChoose, UserChoose },
  props: {
    data: { type: Object, defualt: () => null }
  },
  data () {
    return {
      typeList: [{key: 'normal', label: '否'}, {key: 'append', label: '是'}],
      value: this.data ? transData(this.data) : getDefault()
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.value = this.data ? transData(this.data) : getDefault()
      }
    }
  },
  methods: {
    getData () {
      if (!this.value.type) {
        throw new Error('请选择是否追加预算')
      }
      if (!this.value.applyOrgIds || !this.value.applyOrgIds.length) {
        throw new Error('请选择申报机构')
      }
      if (!this.value.preOrgIds || !this.value.preOrgIds.length) {
        throw new Error('请选择初审机构')
      }
      if (!this.value.finalOrgId) {
        throw new Error('请选择终审机构')
      }

      return JSON.parse(JSON.stringify(this.value, (key, value) => {
        if (key === 'year') {
          return value ? Number(this.$datetime.format(value, 'Y')) : null
        } else if (Array.isArray(value)) {
          return value.join(',')
        } else if (value instanceof Date) {
          return this.$datetime.format(value, 'Y-m-d H:i:s')
        } else {
          return value
        }
      }))
    }
  },
}
</script>
